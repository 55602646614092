<template>
  <div>
    <el-row :gutter="10">
      <el-col :span="12">
        <el-card class="box-card" shadow="never">
          <template #header>
            <el-row justify="space-between" align="middle">
              <span>
                公告</span>
              <el-button class="button" type="text" @click="$router.replace({path:'/notice/list'})">更多</el-button>
            </el-row>
          </template>
          <div v-if="notices && notices.length>0" class="text item">
              <el-row class="notice-row" justify="space-between" align="bottom" v-for="notice in notices" :key="notice.id">
                <div>
                  <el-link v-if="notice.placement" icon="el-icon-bell" class="notice-title" type="success" href="javascript:void(0);" @click="onViewNoticeClick(notice)">{{notice.title }}</el-link>
                  <el-link v-else class="notice-title indent" type="primary" href="javascript:void(0);" @click="onViewNoticeClick(notice)">{{notice.title }}</el-link>
                </div>
                <span style="color:#ccc;font-size:13px">{{notice.updateTime }}</span>
              </el-row>
          </div>
          <div v-else>
            <el-empty description="暂无数据"></el-empty>
          </div>
        </el-card>
      </el-col>
      <el-col :span="12">
        <el-card class="box-card" shadow="never">
          <template #header>
            <el-row justify="space-between" align="middle">
              <span>关注的项目</span>
              <el-button class="button" type="text" @click="$router.replace({path:'/project/subscription/list'})">更多</el-button>
            </el-row>
          </template>
          <div v-if="projects && projects.length>0" class="text item">
            <el-row class="project-row" justify="space-between" align="bottom" v-for="project in projects" :key="project.id">
                <div>
                  <el-badge :value="project.status&&project.status.title" class="item" :type="project.status?dict.projectStatusTypes[project.status.option]:'info'">
                  <el-link class="project-title indent" type="primary" href="javascript:void(0);" @click="onViewProjectClick(project)">{{project.title + " 【当前阶段：" + computeCurrentStage(project) + "】" }}</el-link>
                  </el-badge>
                </div>
                <span style="color:#ccc;font-size:13px">{{project.createTime }}</span>
              </el-row>
          </div>
          <div v-else>
            <el-empty description="暂无数据"></el-empty>
          </div>
        </el-card>
      </el-col>
    </el-row>
    <el-row>
    </el-row>
    <el-dialog v-model="showDialog" :append-to-body="true" destroy-on-close>
        <component :is="component" @close="onComponentClose" :data="currentDialogData"></component>
    </el-dialog>
  </div>
</template>

<script>
import HomeApi from '@/apis/home/home'
import NoticeDetail from '@/views/home/coms/noticeDetail'
import moment from "moment"

export default {
  name: 'HelloWorld',
  components:{ NoticeDetail },
  props: {
    msg: String
  },
  data(){
    return {
      component:null,
      showDialog:false,
      currentDialogData: null,
      notices:[],
      projects:[],
      total:0,
      listQuery: {
        condition: {
        },
        pageInfo:{
          pageIndex: 1,
          pageSize: 5
        }
      },
      pageSize: {
        default:5,
        size:[5, 10]
      },
      dict:{
        projectStatusTypes:{
          1:'',
          2:'',
          3:'warning',
          4:'danger',
          5:'danger',
          6:'success',
          7:'warning',
        }
      }
    }
  },
  created(){
    this.getNotices()
    this.getSubscriptions()
  },
  methods:{
    getNotices(){
      // HomeApi.getNotices().then(resp=>{
      //   this.notices = resp.data.content
      // })
    },
    getSubscriptions(pageInfo){
      // HomeApi.getSubscriptions({ pageInfo: pageInfo, condition: { ...this.listQuery }}).then(resp=>{
      //   this.projects = resp.data.content
      // })
    },
    onComponentClose(){

    },
    onViewNoticeClick(notice){
      this.component = NoticeDetail
      this.showDialog = true
      this.currentDialogData = notice
    },
    onViewProjectClick(project){
      console.log("project", project);
      this.$router.pushTo(this.$router.routesConfig.projectEdit, project.id )
      this.$router.push({name:this.$router.routesConfig.projectEdit.name, params:{id:row.document.projectStage.project.id, documentId: row.document.id}})
    },
    computeCurrentProgress(project){
      if (project.stages && project.stages.length > 0) {
        //未上传文档集索引
        var documentIndex
        //未完成阶段索引
        var stageIndex = project.stages.findIndex(
          (s) => {
            documentIndex = s.documents.findIndex((d) => !(d.versions && d.versions.length > 0))
            return documentIndex>=0
          }
        );
        if (stageIndex>=0 && documentIndex>=0) {
          //未完成，根据当前阶段、文档和当前时间确定状态
          var lastDocIndex = project.stages[project.stages.length-1].documents.length-1
          var lastDoc = project.stages[project.stages.length-1].documents[lastDocIndex]
          if(moment(lastDoc.expiry).isBefore(moment())){
            return 'danger'
          }
          var currentDocument = project.stages[stageIndex].documents[documentIndex]
          if (!currentDocument) {
            return ''
          }
          return moment(currentDocument.expiry).isBefore(moment())?'warning':''
        }else{
          //全部完成，再检查是否超期
          var lastDocIndex = project.stages[project.stages.length-1].documents.length-1
          var lastDoc = project.stages[project.stages.length-1].documents[lastDocIndex]
          return moment(lastDoc.expiry).isBefore(lastDoc.versions[0].updateTime)?'warning':'success'
        }
      }
      return ''
    },
    computeCurrentStage(project) {
      if (project.stages &&project.stages.length > 0) {
        var stage = project.stages.find(
          (s) => !s.documents.every((d) => d.versions && d.versions.length > 0)
        );
        if (stage) {
          return stage.title
        }else{
          return '已完成'
        }
      }
      return '--';
    }
  }
}
</script>

<style lang="scss" scoped>
.notice-row{
  padding: 10px 0 0;
  &:first-child{
    padding: 0;
  }

  .notice-title{
    // padding: 5px 0 0;
    font-size: 16px;
    &.indent{
      margin-left: 23px;
    }
  }

}
.project-row{
  padding: 10px 0 0;
  &:first-child{
    padding: 0;
  }

  .project-title{
    // padding: 5px 0 0;
    font-size: 16px;
    &.indent{
      margin-left: 23px;
    }
  }

}
</style>
